// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";


.card {
    border: none; /* Quita el borde */
    border-radius: 10px; /* Redondea las cuatro esquinas */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Aplica la sombra */
    background-color: #2f353a;
}

.main {
    background-color: #242424; /* Cambia el color de fondo según tu preferencia */
    color: $secondary;
}

.breadcrumb {
    border: none; /* Elimina el borde superior */
    background-color: $gray-800;
}


.btn:not(.white):not([color="link"]):not(.excluir-sombra) {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Puedes ajustar los valores según sea necesario */
  }

  .card-header {
    background-color: #2f353a;
    border-bottom: 1px solid $ultra-dark;
  }


  .card-footer {
    border-top: 1px solid $ultra-dark;
  }

  .navbar-nav .nav-link {
    color: $white;
}
  .navbar-nav .nav-link:hover, .navbar-nav .nav-link:focus {
    color: $secondary;
}

.table {
  color: $secondary;
}

.table th, .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid $secondary;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid $secondary;
}

  .btn-dropdown {
    box-shadow: none !important;
  }

  .background-image {
    background-image: url('../assets/img/background.jpg'); /* Ruta relativa al directorio público */
    background-size: cover;
    background-repeat: no-repeat;
  }
.app-header {
  background-color: $ultra-dark;
  //border-bottom: $dark;
  border-bottom:   0px solid $secondary;
  //box-shadow: 0px 2px 4px rgba(19, 213, 223, 0.5);
  box-shadow: 0px 2px 4px rgba(41, 42, 42, 0.5);  //luz verde
}

//CUSTOM SWITCH TURCO
//-----------------------------
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $gray-100;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #2f353a;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: $secondary;
}

input:focus + .slider {
  box-shadow: 0 0 1px $secondary;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.8);
  background-clip: padding-box;
  border: 1px solid rgba(60, 60, 60, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-content .card-body {

  background-color: #fff;
  
  

}
.modal-header {

  background-color: #fff;
  border-bottom: 1px solid $secondary;

}
.modal-content .card-header {

  background-color: #fff;
  border-bottom: 1px solid $secondary;

}

.modal-content .card-footer {
  border-top: 1px solid $secondary;
}

//-----------------------------

//TURCO COSAS LED RECTANGULO

.rectangulo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  bottom: 0;
  right: 10px; /* Ajusta el rectángulo a la derecha dentro de la tarjeta */
  width: 14px;
  height: 50px;
  background-color: $success;
  border-radius: 4px;
}